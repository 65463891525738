import React from 'react'
import "./PrivacyPolicy.css"
import { IoHome } from "react-icons/io5";

const PrivacyPolicy = () => {
    return (
        <>
            <div className='Privacy-container'>
                <div className='header-privacy'>
                    <div className='flex items-center justify-between'>
                        <h1 className='text-2xl font-bold'>Privacy Policy</h1>
                        <div className='flex items-center'>
                            <IoHome />
                            <p className='font-bold'>  Privacy Policy</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='policy-body-container'>
                <div className='ptb-15'>
                    <p>Your Salon (collectively, “Your Salon,” “we,” “us,” and “ours”) is committed to protecting your privacy. This Privacy Notice(“Notice”) describes how Your Salon processes its Personal Data in its capacity as a controller. Your Salon decides what Personal Data is collected and for what purpose we are using) Processor (i.e., Your Salon only processes the data as per the controller’s instructions), as the case may be. It also describes your choices regarding use, access and/or correction of your Personal Data</p>
                </div>

                <div className='quick-links'>
                    <h1 className='headind-h1'>QUICK LINKS</h1>
                    <p className=''>
                        We suggested that you read this Notice in full to ensure you are fully informed. However, if you only want to approach a specific part of this Notice, then you can click on the appropriate link below to jump to that section
                    </p>
                </div>

                <div className='definition mtb-30'>
                    <h1 className=''>DEFINITIONS</h1>
                    <p className=''>
                        The capitalized terms used in the Notice but not defined herein shall have the same meaning as set in the Term of Service
                    </p>
                </div>

                <div className='notice-apply mtb-30'>
                    <h1 className=''>
                        WHOM DOES THIS NOTICE APPLY TO?
                    </h1>
                    <p className=''>
                        This Notice applies to (I) entities or individuals, excluding End -Users and Users, who have subscribed to our Service(s) and have agreed to the Terms(after this referred to as the “Customer”) and (ii) individuals(“Individuals”) from whom Your Salon collects data as a controller as specified below
                    </p>
                </div>

                <div className='queries mtb-30'>
                    <h1 className=''>ANY QUERIES?</h1>
                    <p className=''>
                        If you have any questions or complaints concerning our privacy notice or practices, please contact us at Contact@yoursalon.in
                    </p>
                </div>

                <div className='persnal-data mtb-30'>
                    <h1 className=''>WHAT PERSONAL DATA DOES Your Salon COLLECT AND WHY?</h1>
                    <p className=''>Personal Data we collect and process for our own purposes: Collected Data When you visit our Your Salon websites or participate in Your Salon’s events, Your Salon may collect information; it may involve Personal Data, from Individuals as set forth below( together referred to as “Collected Data”). For the General Data Protection Regulation (GDPR), Your Salon shall be the controller for the collected data- it means that Your Salon decides what Collected Data is processed and why</p>
                </div>

                <div className='salon-procesess'>
                    <h1 className=''>WHEN DOES Your Salon PROCESSES COLLECT DATA?</h1>
                    <p className=''>
                        Sign-up, billing and Account information. Whenever you subscribed and sign-up to any of our Services(s), we may collect your contact information such as:
                    </p>

                    <ul>
                        <li>E-mail address, mailing address, name, IP address, phone number of account admin or geographical location</li>
                        <li>Billing information, such as billing address and credit card number.</li>
                        <li>Name and email address when Account admin/Agent(s) provide feedback from within the Services(s).</li>
                        <li>Unique identifiers, such as username, password or account number.</li>
                    </ul>
                </div>

                <div className=''>
                    <h1>Subject to this Notice and the Terms, we will use data, including without limitation to:</h1>

                    <ul>
                        <li>Send you communication from the Services(s)</li>
                        <li>Provide you the Services(s)</li>
                        <li>Assess the needs of your business to determine or suggest suitable Service(s)</li>
                        <li>Send you requested information about the Service(s).</li>
                        <li>Respond to customer service requests, questions, and concerns.</li>
                        <li>Administer your account</li>
                        <li>Send you promotional and marketing communications (where you have requested us to do so).</li>
                        <li>Facilitate your transactions with other users when you use our Service(s).</li>
                    </ul>

                    <div className='mtb-30'>
                        <h1>EVENTS.</h1>
                        <p> Whenever you attend any event conducted by Your Salon, including webinars or seminars, we may collect your contact information such as e-mail address, name, company name, and designation.</p>
                    </div>

                    <div>
                        <h1>
                            Subject to this Notice, Your Salon will use such data, including without limitation, to:
                        </h1>

                        <ul>
                            <li>Assess the requirements your business to regulate or suggest suitable Service(s)</li>
                            <li>Send you requested information about the Service(s)</li>
                            <li>Send you promotional and marketing communications (where you have requested us to do so)</li>
                            <li>Respond to your questions and concerns.</li>
                        </ul>
                    </div>

                    <div className='mtb-30'>
                        <h1>PROGRAM REGISTRATIONS.</h1>

                        <p>
                            When you register for any of Your Salon programs through a registration form on our Websites, we may gather information such as e-mail address, name, company name and Website URL, company details, contact information, and location. Subject to this Notice, we will use such data, including without any limitation, too
                        </p>
                    </div>

                    <div>
                        <h5>Facilitate your use of the program portal for which you have registered</h5>

                        <ul>
                            <li>Send your communication from within the Service(s).</li>
                            <li>Send you requested information about our Service(s)</li>
                            <li>Respond to your requests, questions and concerns</li>
                            <li>Send you promotional and marketing communications (where you have requested us to do so).</li>
                        </ul>
                    </div>

                    <div className='mtb-30'>
                        <h1>PUBLIC FORUMS, FORMS AND NEWSLETTERS</h1>
                        <p>
                            When you visit Your Salon publicly accessible community forums and blogs or submit any forms on our Website, you must be aware that any information you provide in these areas may be read, collected, and used by others who access them. Further, we may collect your
                        </p>
                    </div>

                    <div className=''>
                        <ul>
                            <li>information about your business, such as company name, company size, business type</li>
                            <li>A short bio about you to identify you as the author of the post.</li>
                            <li>Contact information such as name, e-mail address, mailing address, or phone number. Whenever you determinedly subscribe to our newsletters, we may collect your email address to share our newsletters with you.</li>
                        </ul>
                    </div>

                    <div className='mtb-30'>
                        <h1>Subject to this consideration, we will use such data, including without limitation, to</h1>

                        <ul>
                            <li>Assess the needs of your business to determine or suggest suitable Service(s).</li>
                            <li>Send you requested information about the Service(s).</li>
                            <li>Send you promotional, and marketing communications (where you have asked for us to do so); and (iv) respond to your questions and concerns.</li>
                        </ul>
                    </div>

                    <div>
                        <h1>COOKIES AND SIMILAR TECHNOLOGIES.</h1>

                        <p>
                            Our third-party advertising partners and we use cookies and similar technologies in examining trends, manage the Website, track users movements around the site, and collect demographic information about our user base as a whole. We may accept reports based on the use of these technologies by these companies on an individual and aggregated basis. Most web browsers support cookies, and users can control the use of cookies at the individual browser level. Kindly notice that if you choose to impair cookies, it may limit your use of some features or functions on our Websites and services.
                            As is true of most websites, we gather certain information automatically and store it in log files. This information may include browser type, internet service provider (ISP), internet protocol (IP) addresses, referring/exit pages, the files viewed on Your Salon Website (e.g., HTML pages, graphics, etc.), operating system, date/time stamp, and clickstream data. We link this automatically-collected data to other data we collect about you. We do this mostly to improve the services we offer you to make marketing better, analytics, or Website performance and functionality.
                            <br /><br />
                            Please check the cookies policies for further information and about the use of Cookies and similar technologies.
                        </p>
                    </div>

                    <div className='ptb-20'>
                        <h1>ANALYTICS</h1>

                        <p>
                            Apart from the introductory information collected by us, we receive and record certain Personal information automatically from yours whenever you visit our Websites. It includes device model, IP address, the type of browser used, usage pattern through cookies and browser settings, query logs, and product usage logs. We also associate scrolls, clicks, drop-off and conversions on our Websites and Service(s) to give user journeys in real-time. Subject to this Remark, we will use such data, including without any limitation, to
                        </p><br />

                        <ul>
                            <li>Assess the needs of your business to determine or suggest suitable Service(s)</li>
                            <li>Send you requested information about the Service(s)</li>
                            <li>Respond to customer service requests, questions and concerns for analytical purposes.</li>
                            <li>You empower Your Salon and its service providers to perform analytics on such Collected Data, to (i)compile analytical reports and register insights into usage patterns, and (ii) to enhance, improve support and operate the Websites. You acknowledge that Your Salon uses Collected Data, as the case may be, for the primary purposes</li>
                        </ul>
                    </div>

                    <div>
                        <h1>TESTIMONIALS</h1>
                        <p> Your testimonials/comments/reviews may be posted on our Websites, which may contain your Private Data. Before posting any testimonial, we will obtain your consent and the affidavit along with your name. If you want to remove your testimonial, please contact us at Contact@yoursalon.in.</p>
                    </div>

                    <div className='mtb-30'>
                        <h1>MARKETING COMMUNICATIONS</h1>

                        <p>
                            When you are liable to, we may use your email, collected as part of Collected Data, to send our newsletters and/or marketing communications about our products and services. When you have asked, we will also post your marketing communications about our third-party partners. If you do not wish to receive these communications, you can prefer by following the directions contained in the emails you receive or by contacting us at Contact@yoursalon.in
                            However, we will generally collect Personal Data from you only where we need Personal Data to perform a contract with you. The processing is in our legitimate interests or relies upon your consent, where we are legally required to do so and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect Personal Data from you or may otherwise need the Personal Data to protect your vital interests or those of another person.
                        </p><br /><br />

                        <p>
                            If we ask you to provide Personal Data to comply with a legal requirement or to perform a contract with you, we will make this clear at the relevant time and advise you whether the provision of your Personal Data is compulsory or not (as well as of the possible consequences if you do not provide your Personal Data). Similarly, if we collect and use your data in reliance on our legitimate interests (or those of any third party), we will make clear to you at the appropriate time what those legitimate interests are.
                        </p><br />

                        <p>
                            If you have questions or need further information concerning the legal basis on which we collect and use your Personal Data, please contact us using the contact details provided below.
                        </p>
                    </div>

                    <div className=''>
                        <h1>DATA WE PROCESS ON YOUR BEHALF: SERVICE DATA</h1>
                        <p>We do not own direct the use of Service Data or control, and in fact, we are largely unaware of what data is being stored on our platform and only access such data as justly necessary to provide Service(s) (including to respond to support requests), as otherwise authorised by Customers or as required by law. Until and unless we explicitly agree otherwise in writing, you will not process sensitive private data (such as health data) on our platform.
                        </p><br />

                        <p>
                            As the controller, it shall be your duty to inform the End-Users about the processing and, where required, access necessary consent or authorization for any Personal Information that is collected as a part of the Service Data through your use of the Service(s). Being the processors of Personal information on behalf of our Customers, we follow Customer’s instructions concerning the Service Data to the degree consistent with the functionality of our Service(s). In doing so, we implement technical, physical and administrative measures against illegal processing of such information and loss, damage to, or destruction of, Personal Data.
                        </p>
                    </div>

                    <div className='mtb-30'>
                        <h1>OTHER INFORMATION</h1>
                        <p>
                            Where End-User or User uses the Service(s), we automatically record and receive some accurate information of such users. This data includes device model, the type of browser being used, IP address, usage pattern through cookies and browser settings, query logs, and product usage logs (collectively referred to as “Other Information”). If you are a customer of Your Salon, please note that you will have the option to opt-out of tracking some events from within Your Salon.
                        </p>
                    </div>

                    <div className=''>
                        <h1>MOBILE APPLICATIONS</h1>
                        <p>
                            When you download, install, and use our Mobile Applications, we automatically collect information about the type of device you use, operating system version, and the device identifier (or “UDID”).
                        </p><br />
                        <p> We use mobile analytics software to allow us to better understand the functionality of our Mobile Software on your phone. This Software will record certain information such as the number of times you used the application, events that occur within the application, performance data, integrated usage, and where the application was downloaded from. We do not link any information we store within the analytics software to any personally identifiable information you submit on the Mobile Applications</p>
                    </div>

                    <div className=''>
                        <h1> Subject to this Notice, we will use such data to</h1>
                        <ul className='ptb-10'>
                            <li>Provide and manage Mobile Application</li>
                            <li>If you have opted into receiving push notifications, send you push notifications from time-to-time to update you about any events or promotions that we may be running and/or update you about new features to our Service(s).</li>
                            <li>Send you promotional and marketing communications (where you have requested us to do so). If you no longer wish to receive these types of communications, you may turn them off at the device level</li>
                        </ul>
                    </div>

                    <div className='mtb-30'>
                        <h1>IMPROVING AND ENHANCING OUR SERVICE(S)</h1>
                        <h5> You authorise us and the service providers we use to process the Service information in our systems to</h5>

                        <ul>
                            <li>Provide, improve, enhance, support, and operate the Service(s) and its availability.</li>
                            <li>Develop new products and services and</li>
                            <li>Compile statistical reports and record insights into usage patterns</li>
                        </ul>
                    </div>

                    <div className='mtb-30'>
                        <h1>SINGLE SIGN-ON</h1>
                        <p> You need to use the sign-in services like Instagram, Facebook and our Your Salon website to log-in. The mentioned services will ask you to fill in the personal data such as your name, email id and the mobile number and some other credentials as well. With Instagram and Facebook, you can share information related to your experiences of the services to your profile page. In addition, the information can also be shared with others within your network.</p>
                    </div>

                    <div className=''>
                        <h1>SOCIAL MEDIA FEATURES</h1>
                        <p>
                            We have also included the social media features, like, Facebook “Share this” button and ” like” button or some other interactive mini-programs. While interacting with the features or functionalities, they might check your IP address, and the page you are visiting. To make the best experience for you, they will set the cookie so that every feature can function appropriately. The widgets and features of social media are hosted on the website or by the third party. The privacy notice from the company complies with your interactions. Check out the cookies policy for more information.
                        </p>
                    </div>

                    <div className='ptb-20'>
                        <h1>SHARING OF PERSONAL DATA</h1>
                        <p>
                            The personal data is processed in the United States and the European Economic Area (“EEA”) and also in other countries via third parties that we might use.
                        </p>
                    </div>

                    <div className=''>
                        <h1> We can also share your personal data in below manner:</h1>
                        <ul>
                            <li>With third-party integration, Your Salon offers you the service (s). The sub-processors are addressed to service data and customer’s account as logically.required to give the service (s). It will be directed to classified responsibilities in their terms and conditions agreements.</li>
                            <li>Including third-party service providers catering services, like, marketing, research, and analysis, data enhancement for them to stand out to you on our part</li>
                            <li>Including third payment processors who operate your credit card and additional payment data for Your Salon but are contrarily not authorised to retain, use or store that informationIncluding third-party partners to assist us in onboarding you and administering any additional support required to manage our service(s)</li>
                            <li>Including third-party partners to assist us in onboarding you and administering any additional support required to manage our service(s)</li>
                            <li>Including supporters of contests that you will register</li>
                            <li>Including third-party advertising networks, websites and social media networks</li>
                            <li>With associates in Your Salon and businesses that we will procure in the prospect when they become a member of the Your Salon group for technical.operations, customer support online booking, and some other purposes to enhance, improve and provide the service(s)</li>
                            <li>If there is an involvement of ours in any reorganisation, merger and any other discrete change with the third party, or buy/sell a business system from/to a third party, or if a third party obtains every or a portion of our business, stock or assets with the third party as well as the due diligence stage. By applicable laws, we will exercise the right efforts to notify you of any transfer of Personal Data to an unaffiliated third party.</li>
                        </ul>
                    </div>

                    <div className='ptb-20'>
                        <p>
                            These transfers are included in the service agreements by appropriate recipients, and we have taken suitable protection to guarantee that your Data will endure security under this Privacy Notice. Additional details will be given when requested.
                        </p>
                    </div>

                    <div className='mtb-30'>
                        <h1>INFORMATION THAT WE GET FROM THIRD PARTIES</h1>
                        <p>
                            Your personal information will be updated in a timely manner from the third party. Social media and databases would be the resources to collect information. We will even check that you have given consent to third parties, Or they are legally permitted or are liable to share the information with us.
                            The information such as; your name, postal address, email address, designation, location, telephone numbers etc. will be received from third parties to manage and enhance the records accuracy, customer support experience to improve the sales and other marketing efforts.
                        </p>
                    </div>

                    <div className=''>
                        <h1>PRIVACY PROTECTION COMPLIANCE</h1>

                        <p>
                            Your Salon has engaged in and has verified its compliance with the EU-U.S. and Swiss-US Privacy Shield Framework. Your Salon is bound to govern the Personal Data acquired from European Union (EU) member countries and Switzerland, in confidence on the Privacy Protection Framework, and with the Framework’s applicable Laws. To attain more information on Privacy Protection Framework, visit the U.S. Department of Commerce’s Privacy Shield List. Your Salon complies with the EU-U.S. Privacy Shield and Swiss-U.S. Privacy Shield Frameworks (“Privacy Shield”) as confirmed by the U.S. Department of Commerce for management, collection and retention of that information from the European Union and the United Kingdom and/or Switzerland, as suitable, to the United States in reliance on Privacy Shield.
                        </p><br />
                        <p>
                            Your Salon is accountable for the data it gets from the Privacy protection framework, and consequently, sends to a third party serving as an assistant on its behalf. Your Salon follows the Privacy Shield Principles for the forward variations of personal data from the EU, and Switzerland, and the onward transfer liability prerequisites. Your Salon is obliged to support the EU data protection authorities (DPAs) and the Swiss Federal Data Protection and Information Commissioner (FDPIC) and follows the advice from those authorities about the personal data transferred from the EU and Switzerland considering the employment relationship.
                        </p><br />

                        <p>
                            Concerning the personal data collected or assigned under the Privacy Protection Framework, Your Salon is authorised to the regulatory obligation laws of the U.S. Federal Trade Commission. In such conditions, Your Salon, Inc. can reveal personal information in acknowledgment to legitimate demands by public administrations, to satisfy law enforcement or national security claims.
                        </p><br />

                        <p> If there is an unfinished privacy or data usage concern that we have not marked competently, you can contact us.</p>
                    </div>

                    <div className='mtb-30'>
                        <h1>HOW WE KEEP YOUR INFORMATION SECURE</h1>
                        <p>
                            Your Salon employs proper organisational and technical steps to secure your personal information that we receive and process. The criteria we practise are designed to give a level of security-relevant to the risk of treating your personal information.
                        </p><br />
                        <p>
                            The information security prospects are constantly growing, so are the controls. The controls, thus completed, are regularly examined as per internal and external reviews. If you have issues about the security of your personal information, please reach us directly as explained in this Privacy Notice.
                        </p>
                    </div>

                    <div className='mtb-30'>
                        <h1>ADDITIONAL COMMUNICATIONS</h1>

                        <p>
                            If you are a Your Salon Client, we will notify you after the disclosures associated with the service (s) on times when it is required to do so. Like, if the service (s) is interrupted temporarily for support, we can send you an email. Frequently, you might not withdraw any communications that are not promotional. If you do not want to receive them, you can deactivate the account.
                        </p>
                    </div>

                    <div className='mtb-30'>
                        <h1>PERSONAL INFORMATION RETENTION</h1>
                        <p>
                            If you want to plead that we no longer manage your personal information then, please contact us at Contact@yoursalon.in. The personal information included in the service data is preserved and removed following the Terms.
                        </p><br />
                        <p>
                            However, the preceding, we will preserve the personal information and the service Data as required to fulfil the legal commitments, to defence / litigation purposes, secure specific financial and other records, settle conflicts, and administer our agreements.
                        </p>
                    </div>

                    <div className='mtb-30'>
                        <h1>LINKS TO THIRD PARTY WEBSITES</h1>
                        <p>
                            We have links to other websites that are not maintained or managed by Your Salon. Please understand that we are not liable for the privacy modes of third parties or other websites. We urge you that when you leave our sites and to read the privacy policies of every site that handles personal information
                        </p>
                    </div>

                    <div className='mtb-30'>
                        <h1>AMENDMENTS</h1>
                        <p>
                            Amendments to this declaration will be published to this URL and will be valid when posted. If we perform any substantial changes, we will inform you with a remark on this website before the change grows useful and if you are Your Salon client, via e-mail. Given we will not be informing you if we change the notice to perform insert, deletions or changes to the cookies timely to hold the record of cookies current and valid. It would help if you frequently visit this announcement to review for amendments. Your regular use of our service(s) or website with the publishing of any improvement, change, or modification to this notice shall comply with the acceptance to this notice amendment. You can also suspend use of the service(s) or website, if you do not hold the terms of this notice, or any revised version of this notice.
                        </p>
                    </div>

                    <div className='mtb-30'>
                        <h1>LEGAL DISCLOSURE</h1>
                        <p>
                            We manage the right to disclose the personal data included in the service Data and Collected Data desired by applicable law.
                            It is an acknowledgement to legitimate demands by public administrations, like meeting law enforcement or national security requirements.
                            When we consider that acknowledgement is required to preserve our rights to be satisfied with the court order, judicial proceeding or any other legal process served on us. The service and the collected Data will be served among our Group Companies for the actions authorized in the Terms and this Notice.
                            In the event, Your Salon comes under business development, like, an acquisition or merger by another company, or purchase, Collected Data, customer’s account and service data will possibly be one of the assets transferred. An extended notice will be shown on our website to inform you of any changes. The notification will be sent via an email from Contact@yoursalon.in.<span className='contact-bold'> CONTACTING Your Salon</span>
                        </p>
                        <p>
                            In case of any queries related to the privacy notice or any dealing with Your Salon, you can contact us at Contact@yoursalon.in or can
                            call us at<span className='contact-bold'>+91-8770917519 / 8982976097</span>
                        </p>
                    </div>
                </div>
            </div>


        </>
    )
}

export default PrivacyPolicy