

import React from 'react';
import { data } from './data';
import FeaturesCard from './FeaturesCard';

const Features = () => {

  document.addEventListener('DOMContentLoaded', function () {
    const animatedText = document.querySelector('.animated-text');
  
    function isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  
    function onScroll() {
      if (isInViewport(animatedText)) {
        animatedText.classList.add('in-view');
        window.removeEventListener('scroll', onScroll);
      }
    }
  
    window.addEventListener('scroll', onScroll);
    onScroll(); // Check if the element is already in view when the page loads
  });

  return (
    <div className='feature-page'>
      <div className='features'>
      <div className='features-title animated-text'>
        <h1>Your Salon: <span>The Ultimate CRM Solutions for Hair and Beauty Salons</span></h1>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: "5rem" }}>
        {data.map(item => (
          <div key={item.id} className={`feature-card ${item.id % 2 === 0 ? 'oddeven' : ''}`}>
            <FeaturesCard
              even={item.id % 2 === 0}
              imgUrl={item.imgUrl}
              heading={item.heading}
              para={item.para}
            />
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default Features;