import React, { useState, useEffect, useRef } from 'react';
import logo from "../../Assets/images/YourSalon_Logo-03.png";
import { NavLink, useNavigate } from 'react-router-dom';
import "./AppHeader.css";

const AppHeader = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > window.innerHeight) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const navigation = [
    { item: "About us", path: "/about-us" },
    { item: "Features", path: "/features" },
    { item: "Why Your Salon?", path: "/why-ys" },
    { item: "Pricing", path: "/pricing" },
    { item: "Enquiry", path: "/enquiry" }
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className={`App-header ${scrolled ? 'scrolled' : ''}`}>
      <div className='app-container'>
        <button className="hamburger" onClick={toggleMenu}>
          ☰
        </button>
        <div className="logo" onClick={() => navigate('/')}>
          <img src={logo} alt="logo" />
        </div>
        <ul ref={menuRef} className={`nav-bar ${menuOpen ? 'open' : ''}`}>
          {navigation.map((navItem) => {
            const { item, path } = navItem;
            return (
              <li key={path} onClick={closeMenu}>
                <NavLink to={path} className={({ isActive }) => (isActive ? "active" : "")}>
                  {item}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <div className='demo-btn'>
          <a href="https://wa.me/+918982976097" target="_blank" rel="noopener noreferrer" className="slide_from_right">Book Demo</a>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
