import React from 'react'
import ContactForm from '../ContactForm/ContactForm'
import "./ContactUs.css"

const ContactUs = () => {
  return (
    <div className='contact-container'>
      <div className='Contact-us'>
      <div className='ls-contact-banner'>
            <div className='ls-contact-content'>
              <h1> Contact us</h1>
            </div>
          
                <div className='address-detail'>
                  <div className='register-add'>
                    <h5>REGISTERED ADDRESS</h5>
                    <p>70 Anoop Nagar, Indore 452011</p>
                  </div>
                  <div className='office-add'>
                    <h5>OFFICE ADDRESS</h5>
                    <p>AIC 3rd Floor, Scheme Number 78 Vijay Nagar <br />Indore 452010</p>
                  </div>
                </div>
            </div>
      

          {/* {Rs-Contact-form} */}
          <div className='contact-form'>
            <ContactForm/>
          </div>
        </div>
    </div>
  )
}

export default ContactUs