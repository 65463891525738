// import React from 'react';
// import AboutUs from '../Component/AboutUs/AboutUs';
// import CustomerRetention from '../Component/CustomerRetention/CustomerRetention';
// import Features from '../Features/Features';
// import Buisnessbanner from '../Component/BuisnessBanner/Buisnessbanner';
// import ContactUs from '../Component/ContactUs/ContactUs';
// import MainSection from '../Component/MainSection/MainSection';

// const Home = () => {
//   return (
//     <>
//       <div className="home-grid-container">
//         <MainSection />
//         <div>
//           <div style={{ margin: '5rem 0rem' }}>
//             <AboutUs />
//           </div>
//           <div style={{ margin: '5rem 0rem' }}>
//             <CustomerRetention />
//           </div>
//           <div style={{ margin: '5rem 0rem' }}>
//             <Features />
//           </div>
//           <div style={{ margin: '5rem 0rem' }}>
//             <Buisnessbanner />
//           </div>
//           <div style={{ margin: '5rem 0rem' }}>
//             <ContactUs />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Home;

import React from 'react';
import AboutUs from '../Component/AboutUs/AboutUs';
import CustomerRetention from '../Component/CustomerRetention/CustomerRetention';
import Features from '../Features/Features';
import Buisnessbanner from '../Component/BuisnessBanner/Buisnessbanner';
import ContactUs from '../Component/ContactUs/ContactUs';
import MainSection from '../Component/MainSection/MainSection';

const Home = () => {
  return (
    <div className="home-grid-container">
      <MainSection />
      <div className="content-sections">
        <div style={{ margin: '5rem 0rem' }}>
          <AboutUs />
        </div>
        <div style={{ margin: '5rem 0rem' }}>
          <CustomerRetention />
        </div>
        <div style={{ margin: '5rem 0rem' }}>
          <Features />
        </div>
        <div style={{ margin: '5rem 0rem' }}>
          <Buisnessbanner />
        </div>
        <div style={{ margin: '5rem 0rem' }}>
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default Home;
