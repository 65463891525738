import React from 'react'
import "./Pricing.css"
import PriceCard from '../PriceCard'
const Pricing = () => {
  return (
    <div className='pricing'>
       <div className='pricing-banner'>
         <div className='pricing-title'>
            <h1>Customize your growth path : discover our crm pricing tiers </h1>

            <h4>Explore a range of scalable CRM pricing tiers tailored to fit your business needs.Unlock the power of customization
                and chart your path to sustainable growth with our flexible pricing options."</h4>
         </div>

         <div className='flex-between'>
           <PriceCard 
           headerTitle="Quaterly Package"
           amount="1500"          
           bodyAmount="4500"/>
           <PriceCard
            headerTitle="Half yearly Package"
            amount="1200"          
            bodyAmount="7200"
           />
           <PriceCard
            headerTitle="Yearly Package"
            amount="1000"          
            bodyAmount="12000"/>
         </div>
       </div>
    </div>
  )
}

export default Pricing