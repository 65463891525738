import React, { useEffect, useRef } from 'react';
import "./CustomerRetention.css";
import { data } from "./data";

const CustomerRetention = () => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const elementIndex = elementsRef.current.indexOf(entry.target);
            const isLeft = elementIndex % 2 === 0;
            entry.target.classList.add(isLeft ? 'slide-top' : 'slide-bottom');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elementsRef.current.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, []);

  return (
   <div className='customer-retention-container'>
     <div className='customer-retention'>
      <div className='why-ys'>
        <h1>What we offer</h1>
      </div>
      <div className='ys-points'>
        {data.map((item, index) => (
          <div
            className='ys-point-para item'
            key={item.id}
            ref={(el) => (elementsRef.current[index] = el)}
          >
            <div className='ys-img'>
              <img src={item.imgUrl} alt='' />
            </div>
            <div className='ys-bottom'>
              <h4>{item.title}</h4>
              <p>{item.para}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
   </div>
  );
};

export default CustomerRetention;
