import React, { useState } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import "./ContactForm.css";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        date: '',
        time: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
     
    const showToast = () => {
        toast.success('Your information has been successfully submitted');
      }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            valid = false;
        }
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
            valid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
            valid = false;
        }
        if (!formData.date) {
            newErrors.date = 'Date is required';
            valid = false;
        }
        if (!formData.time) {
            newErrors.time = 'Time is required';
            valid = false;
        }
        if (!formData.message.trim()) {
            newErrors.message = 'Message is required';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                const response = await axios.post('https://api.yoursalon.in/api/owner/webdetails', formData);
                
                console.log(response.data);
            } catch (error) {
                console.error('Error submitting form:', error);
            }
            showToast()
        }
     setFormData({
        name:'',
        email: '',
        date: '',
        time: '',
        message: '',
     })
    };

    return (
        <div className=''>
            <div className='input-fields'>
                {/* <div> */}
                    <input
                        type='text'
                        placeholder='Your name'
                        name='name'
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    {errors.name && <div className="error-message">{errors.name}</div>}
                {/* </div> */}
                {/* <div> */}
                    <input
                        type='email'
                        placeholder='Your Email'
                        name='email'
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    {errors.email && <div className="error-message">{errors.email}</div>}
                {/* </div> */}
                {/* <div> */}
                    <input
                        type='date'
                        placeholder='Choose a day'
                        name='date'
                        value={formData.date}
                        onChange={handleInputChange}
                    />
                    {errors.date && <div className="error-message">{errors.date}</div>}
                {/* </div> */}
                {/* <div> */}
                    <input
                        type='time'
                        placeholder='Choose a time'
                        name='time'
                        value={formData.time}
                        onChange={handleInputChange}
                    />
                    {errors.time && <div className="error-message">{errors.time}</div>}
                {/* </div> */}
                <div className='text-area'>
                    <textarea
                        rows={6}
                        cols={130}
                        name='message'
                        placeholder='Your message'
                        style={{ resize: 'none' }}
                        value={formData.message}
                        onChange={handleInputChange}
                    />
                    {errors.message && <div className="error-message">{errors.message}</div>}
                </div>
                <div className='ContactForm-submit'>
                <button className='submitbtn' onClick={handleSubmit}>Submit</button>
            </div>
            </div>
            <ToastContainer/>
        </div>
    );
};

export default ContactForm;
