import React from 'react'

import { IoHome } from "react-icons/io5";
import "./RefundAndCancellation.css"

const RefundAndCancellation = () => {
  return (
   <>
    
       <header className='header'>
         <h1>Refund and Cancellation Policy</h1>
         <div className='right-side'>
         <IoHome />
         <p>Refund and Cancellation Policy</p>
         </div>
       </header>
       <div className='refundpolicy-container'>
        <div className='ptb-10'>
            <p>At Your Salon, we are committed to providing our customers with high-quality software solutions. We understand that sometimes circumstances may arise that require cancellations or returns. Please read our cancellation and return policy carefully to understand our procedures and guidelines</p>
        </div>

        <div>
            <h4 className='ptb-10'>Cancellation Policy:</h4>

            <h5 className='ptb-10'>Subscription Cancellations:</h5>

            <p className='ptb-10'>
            We do not offer refunds for any subscription payments once they have been made. This policy is in place to protect the privacy and uniqueness of the software we develop for our clients.
            </p>

            <p className='ptb-10'>
            Subscription cancellations may be allowed but will not result in a refund of the subscription fees paid. Any remaining subscription period will continue until its expiration date.
            </p>
        </div>

        <div>
            <h1>Cancellation Process:</h1>
            <p>To request a subscription cancellation, please contact our customer support team through our website or via email at <a> Contact@yoursalon.in</a></p><br></br>
            <p>Include your subscription details, such as your name and contact details, when making the cancellation request.</p>
        </div>

        <div className='ptb-15'>
            <h1>Exceptions:</h1>
            <p className='ptb-10'>In rare cases, where your salon fails to deliver the promised output or there are significant issues with the software, we may consider partial or full refunds on a case-by-case basis. These cases will be evaluated by our customer support team.</p>
        </div>

        <div className='ptb-10'>
            <h1 className='ptb-10'>Refund Eligibility:</h1>
            <p>We do not offer refunds for software. Once the deployment is initiated or the subscription is activated, there will be no refunds</p>
        </div>

        <div>
            <h1>Exceptions</h1>
            <p>In the unlikely event that Your Salon fails to deliver the agreed-upon software solution or if there are significant issues with the software that cannot be resolved, we will work with you for replacement service, as determined by our team</p>
        </div>

        <div>
            <h1 className='ptb-15'>Contact Information:</h1>
            <p className='ptb-10'>If you have any questions or need further assistance regarding our cancellation and return policy, please contact our customer support team:</p>

            <p className='ptb-10'>Customer Support Email:<a>Contact@yoursalon.in</a></p>
            <p className='ptb-10'>Customer Support Phone: +91-8982976097</p>
        </div>

        <div className='ptb-15'>
            <p className='ptb-10'>
            Please note that Your Salon reserves the right to make changes to this cancellation and return policy at any time. Any updates or modifications will be posted on our website with the updated effective date
            </p>

            <p className='ptb-10'>
            By using our software and subscribing to our software solutions, you agree to abide by this cancellation and return policy.
            </p>

            <p className='ptb'>
            Thank you for choosing Your Salon for your software needs. We look forward to serving you and ensuring your satisfaction with our services.
            </p>

            <p className='ptb-10'>Top of Form</p>
        </div>
       </div>
       </>
  )
}

export default RefundAndCancellation