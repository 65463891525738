import relationImg from "../../Assets/images/customer-relation.png"
import analyticsImg from "../../Assets/images/analytics.png"
import userfriendlyImg from "../../Assets/images/userfriendly.jpg"
import integrationImg from "../../Assets/images/integration.jpg"

export const data = [
    {
        id: 1,
        imgUrl: relationImg,
        title: 'User-Friendly Interface',
        para: 'Our software is designed with client satisfaction in mind. Simplify business functioning with our easy-to-use and user-friendly salon and spa software.'
    },
    {
        id: 2,
        imgUrl: analyticsImg,
        title: 'Analytics and Insights',
        para: 'Our advanced reporting tools provide result-driven insights with comprehensive analysis. Make informed decisions to take Your Salon to new heights'
    },
    {
        id: 3,
        imgUrl: userfriendlyImg,
        title: 'Customer Relationship Management ',
        para: 'Make your customer feel valued and supported at every stage with our beauty and hair salon software. Enhance client relationships with loyalty programs, feedback management, appointment tracking, personalized suggestions.'
    },
    {
        id: 4,
        imgUrl: integrationImg,
        title: 'Seamless Online Platform Integration',
        para: 'Let your client stay connected with you on the digital platforms. Integrate social media platforms, websites, and booking portals to boost customer engagement. '
    },

]