import React from 'react'

import "./TermsAndCondition.css"

const TermsAndConditions = () => {
  return (
    <>
      <header className='header'>
        <h1>terms and condition</h1>
      </header>
      <div className='term-condition-container'>
        <div className='mt-30 mb-30'>
          <p>
            Your salon is a leading registered company that provides software services to Salon and Spa owners and helps them in creating customised mobile apps for their clients to make easy online appointments and bookings.
          </p>
        </div>

        <div className=''>
          <p className='ptb-10'>
            The supply of products and services is subject to the compliance of these terms and conditions.
          </p>

          <ul>
            <li>Complete your online registration and pay the subscription amount for the services. Click on I agree to get access to the services and make an agreement with us considering the terms and conditions.</li>
            <li>Your salon can change these policies at any time due to different reasons. You can get information about such updates on the website and emails. Your salon will obtain your consent regarding such changes. If you don’t want to accept the condition then you can stop using that service. Continuation of the services will indicate that you have agreed to the changes</li>
            <li>Your salon can update, improves, alter and change services at any time. However, if Your salon discontinues the service you will receive an advance notice.</li>
            <li>We will provide you non-exclusive, non-transferable personal right to access and use the Services. The services will be available 24/7. We will make documentation related to services.</li>
            <li>The salon app will be available on the Apple play store. Your salon is not liable for any costs associated with the play store.  It is the responsibility of the salon to make it available on the platform. Your salon can hold no liability for the app performance and the cost incurred.</li>
            <li>You will not transfer, access or store any viruses or unlawful and obscene materials during your services that are ethically offensive or illegal. We reserve the right to disable your access in case of a breach of this provision.</li>
            <li>You will not attempt to copy, transmit or duplicate our services and make all attempts to prevent unauthorised use of the software. Your salon is not liable for any legal issue you have in the salon due to using the software.</li>
            <li>You should take all reasonable steps to ensure customers did not use services for prohibited activities and corporate in reducing fraud related to payment processing by sharing the right information.</li>
            <li>Your salon shall not be required to supply replacement parts or repair damage caused by Fire, Water, Violence or other circumstances beyond the control of the parties. Your salon is not liable for ancillary products or services provided by a third party.</li>
            <li>You will own all the rights to all the customer data which you share for using the services. Your salon will not be responsible for the loss, alteration, damage or corruption of customer data caused by third parties. Your salon may share salon and customer data with a third party to make service accesible.</li>
            <li>Your salon’s handling of all customer data is subject to privacy laws. The company is obliged to adhere to all privacy laws.</li>
            <li>Your salon does not approve any third-party website nor the content available via through Services. We recommend you check their privacy policy by yourself. Your salon is not liable for the content of any Emails, SMS or any other communication medium, sent from the software.</li>
            <li>Your salon assures that the Services will be performed substantially as per the Documentation. Your salon is not responsible for any loss or damage caused due to the internet or other communication facilities.</li>
            <li>You will provide us with all necessary cooperation concerning laws, security, and customer support to maintain all necessary licences and consents.</li>
            <li>You will pay us all the services, subscriptions and additional charges as stated in your order. If for any reason we do not receive any payment, we may be liable to disable your password, account and access of services.</li>
            <li>We own all intellectual property rights in the services and does not give you the rights to patents, copyrights, or database right of the services.</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default TermsAndConditions