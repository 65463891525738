
import React from 'react';
import "./AboutUs.css";
import aboutusImg from "../../Assets/images/aboutusImg.jpg"

const AboutUs = () => {
  return (
    <>
      <div className='about-us'>
      <div className='banner-2'>
        <div className='about-top-title'>
          <div className='about-title'>
            <h1>YourSalon: Run Your Salon Smoothly With Our Top Notch CRM Solutions 
            </h1>
          </div>
          <div className='about-para-1'>
            <h3>We help small, medium, and large businesses run efficiently with our tailored salon management software. 
            </h3>
          </div>
        </div>

        <div className='about-img-section'>
          <div className='about-img'>
            <img src={aboutusImg} alt='' />
          </div>

          <div className='about-content'>
            <div className='content-one'>
              <div className='scroll-content'>
                <p>
                Our mission is to assist hair, nail, and beauty salons in conducting daily business activities with ease.
                </p>
              </div>
             </div>
               <div className='content-one'>
              <div className='scroll-content'>
                <p>
                Track your customer data, manage appointments, sales, inventories, and many more with us.
                </p>
              </div>
             </div>
            
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default AboutUs;
