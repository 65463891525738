
import React from 'react';
import "./App.css";
import AppHeader from './Component/AppHeader/AppHeader';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from './Home/Home';
import AboutUs from './Component/AboutUs/AboutUs';
import Features from './Features/Features';
import CustomerRetention from './Component/CustomerRetention/CustomerRetention';
import ContactUs from './Component/ContactUs/ContactUs';
import Footer from './Component/Footer/Footer';
import Pricing from './Component/Pricing/PricingPages/Pricing';
import RefundAndCancellation from './Component/PrivacyPolicy/RefundAndCancellation';
import TermsAndConditions from './Component/PrivacyPolicy/TermsAndConditions';
import PrivacyPolicy from './Component/PrivacyPolicy/PrivacyPolicy';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <AppHeader />
        <main style={{ marginTop: '10rem' }}> {/* Ensure top margin for main content */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/features" element={<Features />} />
            <Route path="/why-ys" element={<CustomerRetention />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/enquiry" element={<ContactUs />} />
            <Route path="/privacyandpolicy" element={<PrivacyPolicy />} />
            <Route path="/refundcancellation" element={<RefundAndCancellation />} />
            <Route path="/terms" element={<TermsAndConditions />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;


