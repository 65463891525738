import React from 'react'
import "./BuisnessBanner.css"
import userLogoImg from "../../Assets/images/userlogoImg.png"
import appLogoImg from "../../Assets/images/businesAppLogo.png"
import locationLogoImg from "../../Assets/images/locationIcon.png"
const Buisnessbanner = () => {

  const handleContactClick = () => {
    const footer = document.querySelector('#footer');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>


      <div className='buisness-banner'>
        <div className='buisness-title'>
          <h1> Revitalize Your Salon and Spa Business: Embrace Transformative Strategies for Success!</h1>
          <p>At YourSalon, our dedication extends beyond traditional management. We're here to drive transformation, connecting you with fresh clientele and
            essential tools for business growth.
            unlocking new opportunities and fostering long-term business growth."
          </p>
        </div>

        <div className='buisness-banner-logo'>
          <div className='users-logo'>
            <img src={userLogoImg} alt='' />
            <span> 1M +</span>
            <p>Available users</p>
          </div>
          <div className='appointmentBook-logo'>
            <img src={appLogoImg} alt='' />
            <span>5M +</span>
            <p>Appointments Booked</p>
          </div>

          <div className='location-logo'>
            <img src={locationLogoImg} alt='' />
            <span>50+</span>
            <p>Available Countries</p>
          </div>
        </div>

        <div className='contact-btn'>
          <button onClick={handleContactClick} className='btn btn1'>Contact us Now</button>
        </div>

      </div>

    </>
  )
}

export default Buisnessbanner