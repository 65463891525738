import React from 'react'

const PriceCard = ({headerTitle,headerPara,amount,bodyHeader,bodyAmount}) => {
  return (
    <div className='price-card mtb-2'>
        <div className='price-heading'>
            <h5>{headerTitle}</h5>
            <p>start from <span>{amount}  &#8377;</span>/- per month</p>
        </div>
        <div className='price-body'>

            <div className='price-body-title'>
             <h5>Pricing</h5>
            </div>

            <div className='d-flex'>
            <span> &#8377;</span>
            <h5>{bodyAmount}</h5>
            </div>
        </div>
        <div className='price-footer'>
        <div className='buy-btn'>
                <button>Buy Now</button>
            </div>
        </div>
    </div>
  )
}

export default PriceCard