import React from 'react';
import "./Features.css"

const FeaturesCard = ({ even, imgUrl, heading, para }) => {
  return (
    <div className={`features-container ${even ? 'oddeven' : ''}`}>
      <div className='feature-card-ls'>
        <div className='feature-img'>
          <img src={imgUrl} alt='' />
        </div>
      </div>
      <div className='feature-card-rs'>
        <div className='feature-content-rs'>
          <div>
            <h1>{heading}</h1>
            <p>{para}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturesCard;


// import React from 'react';
// import { data } from './data';
// import FeaturesCard from './FeaturesCard';

// const Features = () => {
//   return (
//     <div className='features mt-5'>
//       <div className='features-title animated-text'>
//         <h1>Unleash growth potential: The ultimate CRM features for salons & spa's!</h1>
//       </div>
//       <div className='features-container'>
//         {data.map(item => (
//           <div className={`feature-card-${item.id % 2 === 0 ? 'ls' : 'rs'} ${item.id % 2 === 0 ? 'oddeven' : ''}`}>
//             <div className='feature-img'>
//               <img src={item.imgUrl} alt={item.heading} />
//             </div>
//             <FeaturesCard
//               key={item.id}
//               even={item.id % 2 === 0 ? true : false}
//               imgUrl={item.imgUrl}
//               heading={item.heading}
//               para={item.para}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Features;
